var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "cap-table"
  }, [_c('div', {
    staticClass: "cap-table__inner"
  }, [_c('b-table', _vm._g(_vm._b({
    ref: "capTable",
    staticClass: "sygni-b-table",
    attrs: {
      "fields": _vm.tableFields,
      "sort-by": _vm.sortBy,
      "items": _vm.tableItems,
      "sort-desc": _vm.sortDesc,
      "no-local-sorting": true,
      "per-page": _vm.perPage,
      "busy": _vm.isBusy
    },
    on: {
      "sort-changed": _vm.onSortChange,
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "thead-top",
      fn: function fn() {
        return [_c('tr', {
          staticClass: "thead-top"
        }, [_c('th'), _c('th', {
          attrs: {
            "colspan": "2",
            "variant": "secondary"
          }
        }, [_vm._v("Founders")]), _c('th', {
          attrs: {
            "colspan": "2",
            "variant": "secondary"
          }
        }, [_vm._v("Investors")]), _c('th', {
          attrs: {
            "colspan": "2",
            "variant": "secondary"
          }
        }, [_vm._v("Options")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "colspan": "3",
            "variant": "secondary"
          }
        }, [_vm._v("Total fully dilluted")])])];
      },
      proxy: true
    }, {
      key: "head(name)",
      fn: function fn() {
        return [_c('div', {
          staticClass: "shareholders"
        }, [_vm._v("Shareholders")])];
      },
      proxy: true
    }, {
      key: "head(totalPercentage)",
      fn: function fn() {
        return [_c('div', [_vm._v("Percent "), _c('br'), _vm._v(" Structure")])];
      },
      proxy: true
    }, _vm._l(_vm.$scopedSlots, function (_, slot) {
      return {
        key: slot,
        fn: function fn(rowData) {
          return [_vm._t(slot, null, null, rowData)];
        }
      };
    }), {
      key: "cell(name)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "cell"
        }, [_vm._v(_vm._s(rowData.item.name))])];
      }
    }, {
      key: "cell(founders.numberOfShares)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "cell text-nowrap text-right"
        }, [_vm._v(_vm._s(_vm._f("numberFormat")(rowData.item.founders.numberOfShares, 0)))])];
      }
    }, {
      key: "cell(founders.value)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "cell text-nowrap text-right"
        }, [_vm._v(_vm._s(_vm._f("numberFormat")(rowData.item.founders.value, 2)))])];
      }
    }, {
      key: "cell(investors.numberOfShares)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "cell text-nowrap text-right"
        }, [_vm._v(_vm._s(_vm._f("numberFormat")(rowData.item.investors.numberOfShares, 0)))])];
      }
    }, {
      key: "cell(investors.value)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "cell text-nowrap text-right"
        }, [_vm._v(_vm._s(_vm._f("numberFormat")(rowData.item.investors.value, 2)))])];
      }
    }, {
      key: "cell(options.numberOfShares)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "cell text-nowrap text-right"
        }, [_vm._v(_vm._s(_vm._f("numberFormat")(rowData.item.options.numberOfShares, 0)))])];
      }
    }, {
      key: "cell(options.value)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "cell text-nowrap text-right"
        }, [_vm._v(_vm._s(_vm._f("numberFormat")(rowData.item.options.value, 2)))])];
      }
    }, {
      key: "cell(totals.numberOfShares)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "cell text-nowrap text-right"
        }, [_vm._v(_vm._s(_vm._f("numberFormat")(rowData.item.totals.numberOfShares, 0)))])];
      }
    }, {
      key: "cell(totals.value)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "cell text-nowrap text-right"
        }, [_vm._v(_vm._s(_vm._f("numberFormat")(rowData.item.totals.value, 2)))])];
      }
    }, {
      key: "cell(totalPercentage)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "cell"
        }, [_c('div', {
          staticClass: "table__arrow d-flex justify-content-end align-items-center pl-4 pr-4 text-right"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(_vm._s(_vm.getTotalPercentage(rowData.item.totals.value)))]), _c('div', {
          staticClass: "arrow"
        }, [_c('sygni-arrow-button')], 1)])])];
      }
    }, {
      key: "top-row",
      fn: function fn() {
        return [_c('td', {
          staticClass: "text-left"
        }, [_vm._v("TOTAL:")]), _c('td', {
          staticClass: "text-nowrap text-right"
        }, [_vm._v(_vm._s(_vm._f("numberFormat")(_vm.totals.founders.numberOfShares, 0)))]), _c('td', {
          staticClass: "text-nowrap text-right"
        }, [_vm._v(_vm._s(_vm._f("numberFormat")(_vm.totals.founders.value, 2)))]), _c('td', {
          staticClass: "text-nowrap text-right"
        }, [_vm._v(_vm._s(_vm._f("numberFormat")(_vm.totals.investors.numberOfShares, 0)))]), _c('td', {
          staticClass: "text-nowrap text-right"
        }, [_vm._v(_vm._s(_vm._f("numberFormat")(_vm.totals.investors.value, 2)))]), _c('td', {
          staticClass: "text-nowrap text-right"
        }, [_vm._v(_vm._s(_vm._f("numberFormat")(_vm.totals.options.numberOfShares, 0)))]), _c('td', {
          staticClass: "text-nowrap text-right"
        }, [_vm._v(_vm._s(_vm._f("numberFormat")(_vm.totals.options.value, 2)))]), _c('td', {
          staticClass: "text-nowrap text-right"
        }, [_vm._v(_vm._s(_vm._f("numberFormat")(_vm.totals.totals.numberOfShares, 0)))]), _c('td', {
          staticClass: "text-nowrap text-right"
        }, [_vm._v(_vm._s(_vm._f("numberFormat")(_vm.totals.totals.value, 2)))]), _c('td', {
          staticClass: "text-nowrap text-right"
        }, [_vm._v("100,00%")])];
      },
      proxy: true
    }, {
      key: "bottom-row",
      fn: function fn() {
        return [_c('td', {
          staticClass: "text-left"
        }, [_vm._v("TOTAL:")]), _c('td', {
          staticClass: "text-nowrap text-right"
        }, [_vm._v(_vm._s(_vm._f("numberFormat")(_vm.totals.founders.numberOfShares, 0)))]), _c('td', {
          staticClass: "text-nowrap text-right"
        }, [_vm._v(_vm._s(_vm._f("numberFormat")(_vm.totals.founders.value, 2)))]), _c('td', {
          staticClass: "text-nowrap text-right"
        }, [_vm._v(_vm._s(_vm._f("numberFormat")(_vm.totals.investors.numberOfShares, 0)))]), _c('td', {
          staticClass: "text-nowrap text-right"
        }, [_vm._v(_vm._s(_vm._f("numberFormat")(_vm.totals.investors.value, 2)))]), _c('td', {
          staticClass: "text-nowrap text-right"
        }, [_vm._v(_vm._s(_vm._f("numberFormat")(_vm.totals.options.numberOfShares, 0)))]), _c('td', {
          staticClass: "text-nowrap text-right"
        }, [_vm._v(_vm._s(_vm._f("numberFormat")(_vm.totals.options.value, 2)))]), _c('td', {
          staticClass: "text-nowrap text-right"
        }, [_vm._v(_vm._s(_vm._f("numberFormat")(_vm.totals.totals.numberOfShares, 0)))]), _c('td', {
          staticClass: "text-nowrap text-right"
        }, [_vm._v(_vm._s(_vm._f("numberFormat")(_vm.totals.totals.value, 2)))]), _c('td', {
          staticClass: "text-nowrap text-right"
        }, [_vm._v("100,00%")])];
      },
      proxy: true
    }], null, true)
  }, 'b-table', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners)), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isBusy,
      expression: "isBusy"
    }],
    staticClass: "text-center text-danger spinner"
  }, [_c('b-spinner', {
    staticClass: "align-middle mr-3"
  }), _c('strong', [_vm._v("Loading...")])], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }